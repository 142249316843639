* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
}

main {
  text-align: center;
  position: relative;
  padding: 4px;
}

section {
  margin: 0.5rem;
  /* margin-top: 2px; */
}

.bg {
  height: 50vh;
}

.btn {
  margin-left: 1rem;
}

.event-list {
  border-top: 2px solid #e9ecef;
}
.event-list:before {
  content: "";
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}

@media screen and (max-width:768px) {
    section {
      margin: 1rem 5px;
    }

    .bg {
      text-align: center;
      height: 40vh;
    }

    .btn {
      margin-left: 0;
      margin-bottom: 10px;
    }


    .events {
      border-top: 2px solid #e9ecef;
    }

    .event-list { 
      border: none;    
    }

    p{ 
      margin-bottom: 3px!important;    
    }
}

@media screen and (max-height: 576px) {
  .bg {
    height: 100vh;
  }
  
}